.navbar .dropdown img {
  margin-right: 5px;
}
.navbar .dropdown-menu {
  margin-top: 5px !important;
  min-width: 92px;
  margin-left: -5px !important;
}
.nodes .item {
  padding-right: 15px;
}
.modal-dialog {
  height: 100%;
}
.modal-content {
  padding: 15px;
}
.modal-header {
  padding: 1rem 1rem;
}
.news_list {
  height: 75vh;
  overflow: scroll;
}
.news_list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.errorMessage {
  margin-top: 5px;
  color: #e63d40;
  margin-bottom: 10px;
}

.image_div {
  display: inline-block;
  position: relative;
}

.btnimg {
  background: rgb(255 255 255 / 25%);
  pointer-events: none;
  width: 80px;
  height: 80px;
  opacity: 1;
  line-height: 90px;
  text-align: center;
  padding: 5px;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal_close{
  width: 100%;
  text-align: center;
}
.modal_close img{
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.color-yellow{
  color: yellow;
}
@media only screen and (max-width: 767px) {
  .article {
    margin: 10px;
    width: auto !important;
  }
}
